<template>
  <div class="podcast-item-wrapper web-config">
    <div class="item-header">
      <div class="row align-items-center">
        <div class="col-12 col-md-7">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 pl-0">
              <li class="breadcrumb-item active">Web Config</li>
            </ol>
          </nav>
        </div>
        <div class="col-12 col-md-5 text-right actions">
          <button class="btn page-action" :disabled="loading" @click="save">
            <i class="fas fa-save"></i>
            Save
          </button>
        </div>
      </div>
    </div>

    <div class="position-relative">
      <div class="loading" v-if="loading">
        <div class="loading-wrapper">
          <LoaderInline/>
        </div>
      </div>
    </div>

    <b-row>
      <b-col cols="10" offset="1">
        <section class="mt-3" v-if="web_config?.home_ads">
          <h4>Home Page Ads</h4>
          <hr class="mt-0">
          <b-form-row class="mb-4">
            <b-col>
              <b-form-group label="Pencil Below Rotator Ad Unit ID">
                <b-input-group>
                  <b-form-input v-model="web_config.home_ads.pencil[0].tag"></b-form-input>
                  <b-input-group-append is-text class="py-0">
                    <b-form-checkbox switch class="mr-n2" v-model="web_config.home_ads.pencil[0].disabled">
                      <span class="sr-only">Switch for following text input</span>
                    </b-form-checkbox>
                    <span class="ml-2">Disable Ads</span>
                  </b-input-group-append>
                </b-input-group>
                <small>An example would be LaMusica_Web_Homepage_PencilUnit_1x1.</small>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col>
              <b-form-group label="Background Ad Unit ID">
                <b-input-group>
                  <b-form-input v-model="web_config.home_ads.take_over[0].tag"></b-form-input>
                  <b-input-group-append is-text class="py-0">
                    <b-form-checkbox switch class="mr-n2" v-model="web_config.home_ads.take_over[0].disabled">
                      <span class="sr-only">Switch for following text input</span>
                    </b-form-checkbox>
                    <span class="ml-2">Disable Ads</span>
                  </b-input-group-append>
                </b-input-group>
                <small>An example would be LaMusica_Homepage_Skin_1x1. Sizes include: 1x1.</small>
              </b-form-group>
            </b-col>
          </b-form-row>

        </section>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PodcastForm from "@/components/admin/podcasts/PodcastForm.vue";
import LoaderInline from "@/components/share/LoaderInline.vue";

export default {
  name: 'WebConfig',
  components: { LoaderInline, PodcastForm },
  data () {
    return {
      loading: false,
      web_config: null
    }
  },
  async mounted () {
    await this.retrieveConfig()
  },
  methods: {
    async retrieveConfig() {
      try {
        this.loading = true
        const data = await this.$http.get('/web-config')
        this.web_config = data.config || this.getDefaultValues()
      } catch (error) {
        this.$notify({
          group: 'notifications',
          title: 'Error',
          text: error.message,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },

    async save () {
      try {
        this.loading = true
        if (this.web_config._id) {
          await this.update()
        } else {
          await this.create()
        }

        this.$notify({
          group: 'notifications',
          title: 'Success',
          text: 'Config updated !!',
          type: 'success'
        })
      } catch (error) {
        this.$notify({
          group: 'notifications',
          title: 'Error',
          text: error.message,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },

    async create () {
      const data = await this.$http.post('/web-config', {
        config: this.web_config
      })

      if (!data.success) {
        throw new Error(data.message)
      }
    },

    async update () {
      const data = await this.$http.put(`/web-config/${this.web_config._id}`, {
        config: this.web_config
      })

      if (!data.success) {
        throw new Error(data.message)
      }
    },

    getDefaultValues () {
      return {
        home_ads: {
          'pencil': [{ tag: '', disabled: false }],
          'take_over': [{ tag: '', disabled: false }],
        }
      }
    }
  }
}
</script>

<style lang="scss">
.web-config {
  .vdatetime-input {
    margin-bottom: 0 !important;
    height: 38px !important;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  .loading {
    position: absolute;
    z-index: 100;
    left: 0;
    top: 0;
    right: 0;
    height: calc(100vh - 120px);
    background: rgba(255,255,255, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid red;

    .loading-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 150px;
      border: 1px solid #777;
      background: #fff;
      box-shadow: 0 5px 10px rgba(0,0,0, .3);

      .loader-inline {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

    }
  }
}
</style>
