<template>
  <b-form class="bootstrap-form">
    <b-form-row>
      <b-col>
        <b-form-group label="Title">
          <b-form-input id="title"
                        type="text"
                        v-model="station.title"
                        required
                        placeholder="Title">
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="City">
          <b-form-input id="city"
                        type="text"
                        v-model="station.city"
                        required
                        placeholder="City">
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Call Sign">
          <b-form-input id="callSign"
                        type="text"
                        v-model="station.callSign"
                        required
                        placeholder="Call Sign">
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Slug">
          <b-form-input id="slug"
                        type="text"
                        v-model="station.slug"
                        required
                        placeholder="Slug">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group label="Station">
          <b-form-input id="station"
                        v-if="station && station.programming"
                        type="text"
                        v-model="station.programming.station"
                        required
                        placeholder="Station">
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Timezone">
          <b-form-select v-model="station.programming.timezone"
                         :options="optionsTimezone"
                         v-if="station && station.programming"
                         class="mb-3" />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Offset">
          <b-form-input id="offset"
                        v-if="station && station.programming"
                        type="number"
                        v-model="station.programming.offset"
                        required
                        placeholder="Offset">
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Background Color">
          <div class="d-inline-flex flex-row">
            <b-form-input id="color"
                        class="color-in mx-2"
                        type="color"
                        v-model="station.mobile_hex_color">
            </b-form-input>
          </div>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group label="Primary Genre Spanish">
          <b-form-input id="primaryGenreSpanish"
                        type="text"
                        v-model="station.primaryGenreSpanish"
                        required
                        placeholder="Primary Genre Spanish">
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Primary Genre English">
          <b-form-input id="primaryGenreEnglish"
                        type="text"
                        v-model="station.primaryGenreEnglish"
                        required
                        placeholder="Primary Genre English">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group label="Secondary Title Spanish">
          <b-form-input id="secondaryTitleSpanish"
                        type="text"
                        v-model="station.secondaryTitleSpanish"
                        placeholder="Secondary Title Spanish">
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Secondary Title English">
          <b-form-input id="secondaryTitleEnglish"
                        type="text"
                        v-model="station.secondaryTitleEnglish"
                        placeholder="Secondary Title English">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group label="Description English">
          <b-form-textarea id="stationDescriptionEnglish"
                           type="text"
                           v-model="station.stationDescriptionEnglish"
                           required
                           placeholder="Description English"
                           :rows="5"
                           :max-rows="5">
          </b-form-textarea>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Description Spanish">
          <b-form-textarea id="stationDescriptionSpanish"
                           type="text"
                           v-model="station.stationDescriptionSpanish"
                           required
                           placeholder="Description Spanish"
                           :rows="5"
                           :max-rows="5">
          </b-form-textarea>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group label="Call Sign ID">
          <b-form-input id="callSignID"
                        type="text"
                        v-model="station.callSignID"
                        required
                        placeholder="Call Sign ID">
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Scan Genres Color Code">
          <b-form-input id="scanGenresColorCode"
                        type="text"
                        v-model="station.scanGenresColorCode"
                        required
                        placeholder="Scan Genres Color Code">
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Mailchimp Station Id">
          <b-form-input id="stationMailchimpId"
                        type="text"
                        v-model="station.mailchimpId"
                        required
                        placeholder="Mailchimp Id">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group label="Twitter English Text">
          <b-form-input id="twitterEnglishText"
                        type="text"
                        v-model="station.twitterEnglishText"
                        required
                        placeholder="Twitter English Text">
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Twitter Spanish Text">
          <b-form-input id="twitterSpanishText"
                        type="text"
                        v-model="station.twitterSpanishText"
                        required
                        placeholder="Twitter Spanish Text">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group label="Facebook English Text">
          <b-form-input id="facebookEnglishText"
                        type="text"
                        v-model="station.facebookEnglishText"
                        required
                        placeholder="Facebook English Text">
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Facebook Spanish Text">
          <b-form-input id="facebookSpanishText"
                        type="text"
                        v-model="station.facebookSpanishText"
                        required
                        placeholder="Facebook Spanish Text">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group label="Facebook Share Image Link">
          <b-form-input id="facebookShareImageLink"
                        type="url"
                        v-model="station.facebookShareImageLink"
                        required
                        placeholder="Facebook Share Image Link">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Instagram Token">
          <b-form-input id="instagramToken"
                        type="url"
                        v-model="station.instagramToken"
                        required
                        placeholder="Instagram token">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group label="Phone Number">
          <b-form-input id="phoneNumber"
                        v-model="station.phone_number"
                        required
                        placeholder="1234567890">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Facebook Username">
          <b-form-input id="facebookUsername"
                        v-model="station.facebook_username"
                        required
                        placeholder="">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Twitter Username">
          <b-form-input id="twitterUsername"
                        v-model="station.twitter_username"
                        required
                        placeholder="">
          </b-form-input>
        </b-form-group>
      </b-col>
      
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group label="Instagram Username">
          <b-form-input id="instagramUsername"
                        v-model="station.instagram_username"
                        required
                        placeholder="">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Youtube Channel ID">
          <b-form-input id="youtubeUsername"
                        v-model="station.youtube_username"
                        required
                        placeholder="">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Tiktok Channel Link">
          <b-form-input id="tiktokUsername"
                        v-model="station.tiktok_username"
                        placeholder="">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group label="WordPress Website URL">
          <b-form-input id="website_url"
                        type="url"
                        v-model="station.websiteURL"
                        required
                        placeholder="Website URL">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group label="Radio URL">
          <b-form-input id="radio_url"
                        v-if="station && station.programming"
                        type="url"
                        v-model="station.programming.radio_url"
                        required
                        placeholder="Radio URL">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group label="WideOrbit Stream URL">
          <b-form-input id="streamURL"
                        type="url"
                        v-model="station.streamURL"
                        required
                        placeholder="WideOrbit Stream URL">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group label="Latitude" description="In decimal form">
          <b-form-input id="latitude"
                        type="text"
                        v-model="station.latitude"
                        required
                        placeholder="Latitude">
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Longitude" description="In decimal form">
          <b-form-input id="longitude"
                        type="text"
                        v-model="station.longitude"
                        required
                        placeholder="Longitude">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group label="Broadcast Frequency">
          <b-form-input id="broadcastFrequency"
                        type="text"
                        v-model="station.broadcastFrequency"
                        required
                        placeholder="Enter broadcast frequency">
          </b-form-input>
          <small>An example value would be <code>93.3 FM</code></small>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Station Physical Address">
          <b-form-input id="stationAddress"
                        type="text"
                        v-model="station.address"
                        required
                        placeholder="Enter physical address">
          </b-form-input>
          <small>
            An example would be <code>11 East 26th Street New York, NY. 10010</code>
          </small>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group label="Mediastream Video MP4 Link">
          <b-form-input id="mediastreamVideo"
                        type="text"
                        v-model="station.vendor.mediastream.mp4"
                        required
                        placeholder="https://mdstrm.com/video/67acd7d....mp4">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <br/>

    <h4>Stream Settings</h4>

    <hr />

    <b-form-row>
      <b-col>
        <div class="status-wrapper-stations">
          <div class="video-labels col-form-label">Is Active</div>
          <label class="switch">
            <input type="checkbox" v-model="station.stream_settings.is_active">
            <span class="slider"></span>
          </label>
        </div>
      </b-col>
    </b-form-row>
    <br />

    <b-form-row>
      <b-col>
        <b-form-group label="AAC Stream URL">
          <b-form-input id="streamURL"
                        type="url"
                        v-model="station.stream_settings.stream_url_aac"
                        required
                        placeholder="AAC Stream URL">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group label="HLS Stream URL">
          <b-form-input id="streamURL"
                        type="url"
                        v-model="station.stream_settings.stream_url_hls"
                        required
                        placeholder="HLS Stream URL">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group label="Zone ID">
          <b-form-input id="adsServerUrl"
                        type="text"
                        v-model="station.stream_settings.ads_zone_id"
                        required
                        placeholder="Zone ID">
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Ads Server Url">
          <b-form-input id="adsServerUrl"
                        type="url"
                        v-model="station.stream_settings.ads_companion_server_url"
                        required
                        placeholder="Ads Server Url">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <h6>Web Companion Ads Size</h6>
        <b-form-row>
          <b-col>
            <b-form-group label="Width">
              <b-form-input id="adsCompanionSizeWidth"
                            type="number"
                            v-model="station.stream_settings.ads_companion_size_width"
                            required
                            placeholder="Width">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Height">
              <b-form-input id="adsCompanionSizeHeight"
                            type="number"
                            v-model="station.stream_settings.ads_companion_size_height"
                            required
                            placeholder="Height">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-col>

      <b-col>
        <h6>Mobile Companion Ads Size</h6>
        <b-form-row>
          <b-col>
            <b-form-group label="Width">
              <b-form-input id="adsCompanionMobileSizeWidth"
                            type="number"
                            v-model="station.stream_settings.ads_companion_mobile_size_width"
                            required
                            placeholder="Width">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Height">
              <b-form-input id="adsCompanionMobileSizeHeight"
                            type="number"
                            v-model="station.stream_settings.ads_companion_size_height"
                            required
                            placeholder="Height">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-col>
    </b-form-row>

    <h4>Chat Settings</h4>

    <hr />
<!-- v-model="livestream.chatEnabled"  v-model="livestream.chatRoomID" -->
    <b-form-row>
      <b-col>
        <b-form-group label="Chat Enabled">
          <small>Activates or deactivates the chat for the livestream.  User must re-enter the chat to see changes in settings.</small><br/>
          <label class="chat-checkbox" for="chatEnabled">
            <input type="checkbox" id="chatEnabled" v-model="station.chat.isEnabled" class="inline-checkbox">
            <span>Chat is enabled</span>
          </label>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Chat Room ID">
          <small>The id of the chatroom connected to this livestream</small>
          <b-form-input id="chatRoomID"
                        type="text"
                        v-model="station.chat.id"
                        placeholder="Add Chat Room ID...">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group label="Admin ID">
          <small>The id of the profile that is used by admins of the room</small>
          <b-form-input id="chatRoomID"
                        type="text"
                        v-model="station.chat.adminId"
                        placeholder="Add Admin Profile ID...">
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Chat Banner Ad ID">
          <small>A DFP tag for an ad that appears above the chat window in mobile</small>
          <b-form-input id="chatBannerAdId"
                        type="text"
                        v-model="station.chatBannerAd.tag"
                        placeholder="LaMusica_App_Radio_Chat_320x50">
          </b-form-input>

          <label class="chat-checkbox" for="bannerAdEnabled">
            <input type="checkbox" id="bannerAdEnabled" v-model="station.chatBannerAd.isDisabled" class="inline-checkbox">
            <span>Disable Banner Ad</span>
          </label>
        </b-form-group>
      </b-col>
    </b-form-row>

    <h6>Chat Welcome Message</h6>
    <small>This is a greeting message that users will see when they enter the chat room</small>

    <b-form-row>
      <b-col>
        <b-form-group label="English">
          <b-form-input id="welcomeMessage"
                        type="text"
                        v-model="station.chat.welcomeMessage.en"
                        required
                        placeholder="Welcome to the lamusica chat!">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Spanish">
          <b-form-input id="welcomeMessage"
                        type="text"
                        v-model="station.chat.welcomeMessage.es"
                        required
                        placeholder="Bienvenido al chat de lamusica!">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <br/>

    <AdConfigurationForm :item="station" />

    <br/>

    <h4 class="web-ads">Mobile App Display Advertisement Settings</h4>

    <hr>

    <b-form-row>
      <b-col>
        <b-form-checkbox
            id="mobileBottom320x50.disable"
            v-model="station.mobile_ads['320x50'][0].disable">
            Disable Ad
        </b-form-checkbox>
        <b-form-group label="Bottom banner Ad Unit ID">
          <b-form-input id="mobileBottom320x50"
                        type="text"
                        v-model="station.mobile_ads['320x50'][0].tag"
                        placeholder="/10297243/LaMusica_App_Radio.......320x50">
          </b-form-input>
          <small>
            This ad unit appears at the bottom in the station page in the app. Size: 320x50.
          </small>
        </b-form-group>
      </b-col>
    </b-form-row>

    <h4 class="web-ads">Web DFP Display Advertisement Settings</h4>

    <hr>

    <b-form-row>
      <b-col>
        <b-form-group label="Above the Fold Ad Unit ID">
          <b-form-input id="webAd300x250"
                        type="text"
                        v-model="station.web_ads['300x250'][0].tag"
                        placeholder="Ad Unit ID">
          </b-form-input>
          <small>
            An example would be <code>Mega106.9_Homepage_ATF_300x250_300x600</code>. Sizes include: 300x250, 300x600.
          </small>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Below the Fold Ad Unit ID">
          <b-form-input id="webAd300x250"
                        type="text"
                        v-model="station.web_ads['300x250'][1].tag"
                        placeholder="Ad Unit ID">
          </b-form-input>
          <small>
            An example would be <code>Mega106.9_Homepage_BTF_300x250_300x600</code>. Sizes include: 300x250, 300x600.
          </small>
        </b-form-group>
      </b-col>
    </b-form-row>

    <br/>

    <b-form-row>
      <b-col>
        <h6>Fixed Bottom Floating Ads</h6>
          <b-form-checkbox
              id="bottomFloatingAds"
              v-model="floatingAdsState"
              @change="updateCheckbox">
          Disable Ad
          </b-form-checkbox>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group label="For Desktop Size">
           <b-form-input id="webAd728x180"
                        type="text"
                        v-model="station.web_ads['728x180'][0].tag"
                        placeholder="Ad Unit ID">
          </b-form-input>
          <small>
            An example would be <code>LaMusica_Web_Fixed_Bottom_Floating_Desktop</code>. Supported Sizes: 728x180, 728x90.
          </small>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="For Mobile Size">
          <b-form-input id="webAd320x100"
                        type="text"
                        v-model="station.web_ads['320x100'][0].tag"
                        placeholder="Ad Unit ID">
          </b-form-input>

          <small>
            An example would be <code>LaMusica_Web_Fixed_Bottom_Floating_Mobile</code>. Supported Sizes: 320x100, 320x50.
          </small>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group label="Above Navigation Ad Unit ID">
          <b-form-input id="webAd970x90"
                        type="text"
                        v-model="station.web_ads['970x90'][0].tag"
                        placeholder="Ad Unit ID">
          </b-form-input>
        </b-form-group>
        <small>
          An example would be <code>Mega106.9_Homepage_970x90_728x90_320x50</code>. Sizes include: 970x90, 728x90, 320x50.
        </small>
      </b-col>
    </b-form-row>

    <br/>

    <b-form-row>
      <b-col>
        <b-form-group label="Pencil Below Rotator Ad Unit ID (1x1)">
          <b-form-checkbox
            id="webAd1270x150"
            v-model="station.web_ads['1270x150'][0].disable">
            Disable Ad
          </b-form-checkbox>

          <b-form-input id="webAd1270x150"
                        class="ads-input"
                        type="text"
                        v-model="station.web_ads['1270x150'][0].tag"
                        placeholder="Ad Unit ID">
          </b-form-input>
        </b-form-group>
        <small>
          An example would be <code>Mega97.9_Homepage_Pencil_Under_Rotator_Banner_1270x550_1270x150</code>. Sizes include: 1270x550, 1270x150.
        </small>
      </b-col>

      <b-col>
        <b-form-group label="Pencil Below Rotator Ad Unit ID (970x90)">
          <b-form-checkbox
            id="webAd1270x150_ALT"
            v-model="station.web_ads['1270x150_ALT'][0].disable">
            Disable Ad
          </b-form-checkbox>

          <b-form-input id="webAd1270x150_ALT"
                        class="ads-input"
                        type="text"
                        v-model="station.web_ads['1270x150_ALT'][0].tag"
                        placeholder="Ad Unit ID">
          </b-form-input>
        </b-form-group>
        <small>
          An example would be <code>Mega97.9_Homepage_Pencil_Under_Rotator_Banner_1270x550_1270x150</code>. Sizes include: 970x90, 728x90, 320x50.
        </small>
      </b-col>

    </b-form-row>

    <b-form-row v-if="pencilError">
      <b-col>
        <div class="alert alert-danger">
          {{ pencilError }}
        </div>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <legend class="col-form-label">Start date</legend>
        <datetime type="datetime"
                  v-model="station.web_ads['1270x150'][0].start"
                  class="theme-orange"
                  :phrases="{ok: 'Continue', cancel: 'Exit'}"
                  use12-hour>
        </datetime>
      </b-col>

      <b-col>
        <legend class="col-form-label">End date</legend>
        <datetime type="datetime"
                  v-model="station.web_ads['1270x150'][0].end"
                  class="theme-orange"
                  :phrases="{ok: 'Continue', cancel: 'Exit'}"
                  use12-hour>
        </datetime>
      </b-col>
    </b-form-row>

    <br/>

    <b-form-row>
      <b-col>
        <b-form-group label="Background Ad Unit ID">
          <b-form-input id="webAd1x1"
                        class="ads-input"
                        type="text"
                        v-model="station.web_ads['1x1'][0].tag"
                        placeholder="Ad Unit ID">
          </b-form-input>
        </b-form-group>
        <small>
          An example would be <code>Mega97.9_Homepage_Skin_1x1</code>. Sizes include: 1x1.
        </small>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <legend class="col-form-label">Start date</legend>
        <datetime type="datetime"
                  v-model="station.web_ads['1x1'][0].start"
                  class="theme-orange"
                  :phrases="{ok: 'Continue', cancel: 'Exit'}"
                  use12-hour>
        </datetime>
      </b-col>

      <b-col>
        <legend class="col-form-label">End date</legend>
        <datetime type="datetime"
                  v-model="station.web_ads['1x1'][0].end"
                  class="theme-orange"
                  :phrases="{ok: 'Continue', cancel: 'Exit'}"
                  use12-hour>
        </datetime>
      </b-col>
    </b-form-row>

    <br />

    <b-form-row>
      <b-col>
        <h6>
          <strong>Sticky Bottom Player Ad Unit</strong>
        </h6>
        <b-form-checkbox
            id="playerStickyAds"
            v-model="playerAdsState"
            @change="updateCheckbox">
        Disable Ad
        </b-form-checkbox>
      </b-col>
    </b-form-row>

    <br />

    <b-form-row>
      <b-col>
        <b-form-group label="Player Ad Unit ID">
           <b-form-input id="webAd468x60"
                        type="text"
                        v-model="station.web_ads['468x60'][0].tag"
                        placeholder="Ad Unit ID">
          </b-form-input>
          <small>
            An example would be <code>LaMusica_Web_Player_468x60</code>. Supported Sizes: 468x60.
          </small>
        </b-form-group>
      </b-col>
    </b-form-row>

  </b-form>
</template>

<script>
  import AdConfigurationForm from '../../share/AdConfigurationForm'
  import {DateTime as LuxonDateTime} from 'luxon'

  export default {
    name: 'StationMetaForm',
    components: {
      AdConfigurationForm
    },
    props: {
      station: {
        type: Object,
        required: true
      },
      pencilError: {
        type: String,
        required: false,
        default: null
      }
    },
    data () {
      return {
        floatingAdsState:false,
        playerAdsState: false,
        optionsTimezone: [
          { value: null, text: 'Select Timezone' },
          { value: 'America/New_York', text: 'America/New York' },
          { value: 'America/Los_Angeles', text: 'America/Los Angeles' },
          { value: 'America/Chicago', text: 'America/Chicago' },
          { value: 'America/Puerto_Rico', text: 'America/Puerto Rico' }
        ]
      }
    },
    methods:{
      updateCheckbox(){
        this.$nextTick( ()=>{
          this.station.web_ads['728x180'][0].disable = this.floatingAdsState
          this.station.web_ads['320x100'][0].disable = this.floatingAdsState
          this.station.web_ads['468x60'][0].disable = this.playerAdsState
        })
      },

      updatePencilCheckbox1() {
        if(this.station.web_ads['1270x150'][0].disable) {
          this.station.web_ads['1270x150_ALT'][0].disable = false
        }
        // console.log(this.station.web_ads[from][0].disable, this.station.web_ads[to][0].disable)
        //
        // if(this.station.web_ads[''][0].disable) {
        //   console.log('H1')
        //   this.station.web_ads[to][0].disable = false
        //
        //   console.log(this.station.web_ads[from][0].disable, this.station.web_ads[to][0].disable)
        // }
        // this.$nextTick( () => {
        //
        // })
      },

      updatePencilCheckbox2() {
        if(this.station.web_ads['1270x150_ALT'][0].disable) {
          this.station.web_ads['1270x150'][0].disable = false
        }
        // this.$nextTick( () => {
        //
        // })
      }
    },
    created () {
      if(!this.station.stream_settings) {
        this.station.stream_settings = {
          is_active: false,
          stream_url_aac: null,
          stream_url_hls: null,
          zone_id: null,
          companion_server_url: null,
          ads_companion_size_width: 300,
          ads_companion_size_height: 250,
          ads_companion_mobile_size_width: 300,
          ads_companion_mobile_size_height: 250,
        }
      }
    },
    mounted(){
      if(this.station.web_ads['728x180'][0].disable){
        this.floatingAdsState = this.station.web_ads['728x180'][0].disable // initializing checkbox
      }

      if(this.station.web_ads['468x60'][0].disable){
        this.playerAdsState = this.station.web_ads['468x60'][0].disable // initializing checkbox
      }
    }
  }
</script>

<style scoped>

.ads-input {
  margin-bottom: 0 !important;
}

.color-in{
  width: 45px;
}

</style>
